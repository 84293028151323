import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Transaction")]),_c(VSpacer),_c(VBtn,{staticClass:"me-n3 mt-n2",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c(VList,{staticClass:"py-0"},_vm._l((_vm.transactionData),function(data,index){return _c(VListItem,{key:data.transaction,staticClass:"d-flex align-center px-0",class:index > 0 ? 'mt-4':''},[_c(VAvatar,{class:("v-avatar-light-bg " + (data.avatarColor) + "--text me-3"),attrs:{"size":"40","rounded":"","color":data.avatarColor}},[_c(VImg,{attrs:{"max-height":"20","max-width":"20","contain":"","src":data.avatar}})],1),_c('div',{staticClass:"d-flex align-center flex-grow-1 flex-wrap"},[_c('div',{staticClass:"me-2"},[_c(VListItemTitle,{staticClass:"text-sm font-weight-semibold"},[_vm._v(" "+_vm._s(data.title)+" ")]),_c(VListItemSubtitle,{staticClass:"text-xs"},[_vm._v(" "+_vm._s(data.subtitle)+" ")])],1),_c(VSpacer),_c('div',{staticClass:"d-flex align-center"},[_c('h4',{staticClass:"text-base font-weight-semibold me-1"},[_vm._v(" "+_vm._s(data.transaction)+" ")]),_c(VIcon,{attrs:{"size":"20","color":data.transaction.charAt(0) === '+' ? 'success' :'error'}},[_vm._v(" "+_vm._s(data.transaction.charAt(0) === '+' ? _vm.icons.mdiChevronUp :_vm.icons.mdiChevronDown)+" ")])],1)],1)],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }